


























































import { Component, Vue, Watch } from "vue-property-decorator";
import { Announcement } from "@/interfaces";
import { api } from "@/api";
import VueMarkdown from "vue-markdown";
import { readIsAlpha } from "@/store/auth/getters";

@Component({
  components: {
    VueMarkdown,
  },
})
export default class AnnouncementCard extends Vue {
  public announcements: Announcement[] = [];
  public newAnnouncement: string = null;
  public limit = 20;

  @Watch("limit", { immediate: true })
  public async updateAnnouncements() {
    const response = await api.listRecentAnnouncements(this.limit);
    if (response) {
      this.announcements = response.data;
    }
  }

  public async publishAnnouncement() {
    const announcement = {
      title: null,
      body: this.newAnnouncement,
    };
    const response = await api.createAnnouncement(announcement);
    if (response) {
      this.newAnnouncement = null;
      await this.updateAnnouncements();
    }
  }

  public async mounted() {
    await this.updateAnnouncements();
  }

  get isAlpha() {
    return readIsAlpha(this.$store);
  }

  public async deleteAnnouncement(id) {
    const response = await api.deleteAnnouncement(id);
    if (response) {
      this["$toast"].add({
        severity: "success",
        summary: "Announcement deleted!",
        life: 3000,
      });
      await this.updateAnnouncements();
    } else {
      this["$toast"].add({
        severity: "error",
        summary: "Something went wrong...",
        detail: "Could not delete announcement",
        life: 3000,
      });
    }
  }
}
