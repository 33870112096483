






































































































import dayjs from "dayjs";
import { Component, Vue, Watch } from "vue-property-decorator";
import { api } from "@/api";
import { readOffices } from "@/store/main/getters";
import { dispatchListOffices } from "@/store/main/actions";
import "@/assets/styles/account-list-card.scss";

@Component
export default class RecentAccountsCard extends Vue {
  public loading: boolean = true;
  public officeId: number = null;
  public accountType: string = "YouTube Channel";
  public accountTypes = [
    "YouTube Channel",
    "Clip Licensing",
    "Podcast",
    "China Platform",
  ];
  public channels = [];

  public get offices() {
    const offices = readOffices(this.$store);
    return [
      {
        name: "All",
        id: 0,
      },
      ...offices,
    ];
  }

  public get displayMetrics() {
    return (
      this.accountType === "YouTube Channel" ||
      this.accountType === "China Platform"
    );
  }

  @Watch("accountType")
  @Watch("officeId")
  public refreshList() {
    this.fetchAccounts();
  }

  private async fetchAccounts() {
    this.loading = true;

    const response = await api.listRecentAccounts(
      this.accountType,
      this.officeId !== 0 ? this.officeId : undefined
    );

    if (response) {
      this.channels = response.data.map((channel) => {
        return {
          ...channel,
          views: channel.views?.toLocaleString(),
          subscribers: channel.subscribers?.toLocaleString(),
          created_at: dayjs(channel.created_at).format("YYYY-MM-DD"),
          manager:
            channel.manager?.split(",").length > 1
              ? "Multiple"
              : channel.manager,
        };
      });
      this.loading = false;
    }
  }

  public async mounted() {
    const offices = await readOffices(this.$store);
    if (offices.length === 0) {
      await dispatchListOffices(this.$store);
    }
    this.officeId = 0;
  }
}
