





























import { Component, Vue } from "vue-property-decorator";
import { api } from "@/api";

@Component
export default class RevenueCard extends Vue {
  public iframeUrl = null;

  async beforeMount() {
    this.iframeUrl = (await api.getSboIframeUrl()).data;
  }
}
