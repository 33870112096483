import { render, staticRenderFns } from "./AnnouncementCard.vue?vue&type=template&id=209e9780&scoped=true&"
import script from "./AnnouncementCard.vue?vue&type=script&lang=ts&"
export * from "./AnnouncementCard.vue?vue&type=script&lang=ts&"
import style0 from "./AnnouncementCard.vue?vue&type=style&index=0&id=209e9780&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "209e9780",
  null
  
)

export default component.exports