





















































import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class ListCard extends Vue {
  @Prop(String) public icon: string | undefined;
  @Prop(String) public headerColor: string | undefined;
  @Prop(String) public iconColor: string | undefined;
  @Prop(String) public title: string | undefined;
  @Prop(Array) public listData: any[] | undefined;

  get iconClass() {
    return {
      [`p-mr-2 pi pi-${this.icon}`]: !!this.icon,
    };
  }
}
