















































import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class StatItem extends Vue {
  @Prop(String) public title!: string;
  @Prop(String) public type!: string;
  @Prop(Number) public count!: number;
  @Prop(Number) public previousCount: number | null;
  @Prop(Number) public dayCount!: number | null;
  @Prop(Number) public newCount: number | null;

  public get description() {
    return this.previousCount === null
      ? `Overall count of ${this.type === "channels" ? "linked" : ""} ${
          this.type
        }`
      : `Count of newly added ${this.type} within the last ${this.dayCount} days`;
  }

  public get showTrend() {
    return this.previousCount !== null;
  }

  public get delta() {
    return this.previousCount !== null ? this.count - this.previousCount : null;
  }

  public get percentage() {
    return this.previousCount !== null && this.previousCount !== 0
      ? (Math.abs(this.count - this.previousCount) / this.previousCount) * 100
      : null;
  }
}
