































































































import dayjs from "dayjs";
import { Component, Vue, Watch } from "vue-property-decorator";
import { api } from "@/api";
import { readOffices } from "@/store/main/getters";
import { dispatchListOffices } from "@/store/main/actions";
import "@/assets/styles/account-list-card.scss";

@Component
export default class TopChannelsCard extends Vue {
  public loading: boolean = true;
  public officeId: number = null;
  public rankField: string = "views";
  public rankFields = [
    {
      label: "By Views",
      value: "views",
    },
    {
      label: "By Subs",
      value: "subscribers",
    },
  ];
  public channels = [];

  public get offices() {
    const offices = readOffices(this.$store);
    return [
      {
        name: "All",
        id: 0,
      },
      ...offices,
    ];
  }

  @Watch("rankField")
  @Watch("officeId")
  public refreshList() {
    this.fetchChannels();
  }

  private async fetchChannels() {
    this.loading = true;

    const apiParams = {
      order_by: this.rankField,
    };
    if (this.officeId !== 0) {
      apiParams["office_id"] = this.officeId;
    }
    const response = await api.overviewTopChannels(apiParams);

    if (response) {
      this.channels = response.data.map((channel) => {
        return {
          ...channel,
          views: channel.views.toLocaleString(),
          subscribers: channel.subscribers.toLocaleString(),
          manager:
            channel.manager?.split(",").length > 1
              ? "Multiple"
              : channel.manager,
        };
      });
      this.loading = false;
    }
  }

  public async mounted() {
    const offices = await readOffices(this.$store);
    if (offices.length === 0) {
      await dispatchListOffices(this.$store);
    }
    this.officeId = 0;
  }
}
