



































import { Component, Vue } from "vue-property-decorator";
import AnnouncementCard from "@/views/overview/cards/AnnouncementCard.vue";
import ContractsLatestExpiringCard from "@/views/overview/cards/ContractsLatestExpiringCard.vue";
import MilestonesCard from "@/views/overview/cards/MilestonesCard.vue";
import RecentAccountsCard from "@/views/overview/cards/RecentAccountsCard.vue";
import RevenueCard from "@/views/overview/cards/RevenueCard.vue";
import StatsCard from "@/views/overview/cards/StatsCard.vue";
import TopChannelsCard from "@/views/overview/cards/TopChannelsCard.vue";
import OmniSearchBox from "@/components/OmniSearchBox.vue";
import UserInfo from "@/components/UserInfo.vue";
import { readIsAlpha } from "@/store/auth/getters";
import TopBar from "@/components/TopBar.vue";

@Component({
  components: {
    AnnouncementCard,
    ContractsLatestExpiringCard,
    MilestonesCard,
    RecentAccountsCard,
    RevenueCard,
    StatsCard,
    TopChannelsCard,
    OmniSearchBox,
    UserInfo,
    TopBar,
  },
})
export default class Overview extends Vue {
  get isAlpha() {
    return readIsAlpha(this.$store);
  }

  public mounted() {
    window["analytics"]["page"]("/overview");
  }
}
