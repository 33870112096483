




































































import { Component, Vue, Watch } from "vue-property-decorator";
import ProgressSpinner from "primevue/progressspinner";
import StatItem from "@/components/StatItem.vue";
import { api } from "@/api";
import { readIsAlpha } from "@/store/auth/getters";
import { readOffices } from "@/store/main/getters";
import { dispatchListOffices } from "@/store/main/actions";

@Component({
  components: {
    ProgressSpinner,
    StatItem,
  },
})
export default class StatsCard extends Vue {
  public activeTab: number = 0;
  public isLoading: boolean = true;
  public stats = null;
  public officeId = null;

  public get tabs() {
    const tabs = ["Overall", "Monthly"];
    if (this.isAlpha) {
      tabs.push("Yearly");
    }
    return tabs;
  }

  public get offices() {
    const offices = readOffices(this.$store);
    return [
      {
        name: "All",
        id: 0,
      },
      ...offices,
    ];
  }

  private get isAlpha() {
    return readIsAlpha(this.$store);
  }

  public get dayCount() {
    return this.activeTab === 2 ? 365 : 30;
  }

  public get currentCount() {
    switch (this.activeTab) {
      case 0:
        return this.stats.total;
      case 1:
        return this.stats.month;
      case 2:
        return this.stats.year;
    }
  }

  public get previousCount() {
    switch (this.activeTab) {
      case 0:
        return {
          creator: null,
          youtube_channel: null,
          clip_licensing: null,
          china_platform: null,
        };
      case 1:
        return this.stats.previous_month;
      case 2:
        return this.stats.previous_year;
    }
  }

  public changeActiveTab(activeTab: number) {
    this.activeTab = activeTab;
  }

  @Watch("officeId")
  public refreshStats() {
    this.fetchStats();
  }

  private async fetchStats() {
    this.isLoading = true;
    const response = await api.overviewStats(this.officeId || undefined);
    this.stats = response.data;
    this.isLoading = false;
  }

  public async mounted() {
    const offices = await readOffices(this.$store);
    if (offices.length === 0) {
      await dispatchListOffices(this.$store);
    }
    this.officeId = 0;
  }
}
