










import { renameKeys } from "@/utils";
import { api } from "@/api";
import { Component, Vue } from "vue-property-decorator";
import ListCard from "@/components/ListCard.vue";

@Component({
  components: {
    ListCard,
  },
})
export default class ContractsLatestExpiringCard extends Vue {
  public latestExpiringContracts: any[] = [];

  public mounted() {
    this.fetchLatestExpiringContracts();
  }

  async fetchLatestExpiringContracts() {
    const response = await api.listContracts("expiring", 14);

    if (response) {
      const newKeys = { name: "title", expiration_date: "date" };
      this.latestExpiringContracts = response.data.map((item) => {
        return renameKeys(item, newKeys);
      });
    }
  }
}
