












































































import { Component, Vue } from "vue-property-decorator";
import ProgressBar from "primevue/progressbar";
import ProgressSpinner from "primevue/progressspinner";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import { api } from "@/api";

@Component({
  components: {
    ProgressBar,
    ProgressSpinner,
    VueSlickCarousel,
  },
})
export default class MilestonesCard extends Vue {
  public isLoading = true;
  public awards = [];

  public async mounted() {
    const response = await api.overviewMilestoneAwards();

    if (response) {
      this.awards = response.data;
      this.awards.forEach((award) => {
        award.upcoming_channels = award.upcoming_channels.map((channel) => {
          const percentage =
            (channel.subscribers / award.subscriber_count) * 100;

          return {
            ...channel,
            subscribers: channel.subscribers.toLocaleString(),
            percentage,
            percentage_formatted: percentage.toFixed(2).toLocaleString(),
            progress_bar_class:
              percentage > 90 ? "progress-bar-90" : "progress-bar-80",
          };
        });
      });
      this.isLoading = false;
    }
  }
}
